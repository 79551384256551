import React from "react";
import Layouts from "../../components/Layouts";
import Seo from "../../components/Seo";

const PrivacyPolicyEnglishPage = () => {
	return (
		<>
			<Seo title="سياسة الخصوصية" />
			<Layouts lang="ar">
				<div className="  my-12 px-0  w-10/12 xl:w-9/12 2xl:w-9/12 mx-auto">
					<div className="w-full prose lg:prose-lg">
						<h1>سياسة الخصوصية</h1>
						<p>
							إن هذا التطبيق (المشار إليه باسم &quot;كاشات&quot;) تديره وتطوره
							شركة فاروس للتمويل متناهي الصغر ش.م.م. يمكن للمستخدم أن يثبته على
							هاتفه ويستخدمه مجانًا دون تحمل تكلفة إضافية. تهدف هذه السياسة الي
							توعية مستخدمي التطبيق والحصول على موافقتهم فيما يتعلق بسياسات جمع
							البيانات الشخصية واستخدامها وإمكانية الإفصاح عنها عند استخدام
							تطبيق كاشات.
						</p>
						<p>
							يوافق المستخدم، عند اتخاذ خطوة استخدام هذا التطبيق، على إجراءات
							جمع المعلومات واستخدامها في ضوء هذه السياسة. وقد راعت الشركة أن
							تحمل المصطلحات المستخدمة في هذه السياسة ذات المعنى المنصوص عليه في
							شروط الاستخدام، ما لم يأتي النص بمعنى مخالفًا لذلك.
						</p>
						<h4>المعلومات التي نجمعها:</h4>
						<p>
							يجوز أن تجمع وتستخدم فاروس المعلومات والبيانات المنصوص عليها
							أدناه:
						</p>
						<p>
							١- المعلومات التي يذكرها المستخدم في نماذج البيانات سواء كانت في
							التطبيق أو على الموقع الالكتروني، بما في ذلك المعلومات التي يتم
							الحصول عليها خلال عملية التسجيل من خلال التطبيق أو التسجيل في
							الخدمات والمراسلات المختلفة عبر البريد الإلكتروني أو خط خدمة
							العملاء. تشمل هذا المعلومات والبيانات البنود الآتية على سبيل الذكر
							لا الحصر: الاسم، والعنوان، ورقم الهاتف، والبريد الالكتروني، والرقم
							القومي، والصورة الشخصية وحسابات التواصل الأجتماعي وغيرها من
							الحسابات الأخري.
						</p>
						<p>
							&nbsp;٢- يجوز، في أثناء استخدام أحد خدماتنا (مثل طلب التمويل) أو
							بعد ذلك، أن نطلب الحصول على مزيد من المعلومات الخاصة بالمستخدم مثل
							المؤهل الدراسي، والمهنة ومجال العمل، والحالة الاجتماعية، فضلا عن
							البيانات المالية والائتمانية. من الممكن أن يتطلب ذلك تقديم ما يثبت
							صحة وسلامة هذه المعلومات طبقا للوائح الحاكمة لممارسة نشاط التمويل
							متناهي الصغر والتي يجوز أن يطرأ عليها بعض التعديلات من وقت لأخر.
						</p>
						<p>
							&nbsp;٣- يمكن لنظام الذكاء الاصطناعي في تطبيق كاشات خلال عملية
							استخدامك للتطبيق أن يطلب منك مجموعة من الصلاحيات الخاصة بجمع
							المعلومات وذلك للأغراض التالية:
						</p>
						<ul>
							<li>
								- ضمان تقديم الخدمة في النطاق المتوفر به خدمات كاشات، وذلك يتطلب
								معرفة نوع الهاتف والموديل ونظام التشغيل ونظام تحديد الموقع
								والمنطقة الزمنية.
							</li>
							<li>
								- بيانات شبكة الهاتف المسجلة لضمان توافر محفظة إلكترونية للعميل
								لسحب أو سداد التمويل&nbsp;
							</li>
							<li>
								- ضمان أن الخط المستخدم مملوكا للشخص ومنعا للاحتيال من خلال
								معرفة حجم الرسائل وسجل المكالمات ومعرض الصور والرسائل.
							</li>
						</ul>
						<p>&nbsp;</p>
						<p>
							٤- معلومات وتفاصيل خاصة بالمعاملات التي تقوم بها على التطبيق أو
							تزود بها مسئولي خدمة العملاء أثناء الاتصال عبر خط الدعم الهاتفي،
							ويجوز أن يتم تسجيل هذه المعاملات والاتصالات.
						</p>
						<p>
							٥ - البيانات التراكمية والسجلات الخاصة بالهاتف التي من الممكن أن
							تشمل: عنوان بروتوكول الانترنت، ومزود خدمة الانترنت (ISP)، واسم
							الجهاز، ونظام التشغيل، ونوع واسم المتصفح، وغيرها من المعلومات
							الخاصة باستخدام البرامج والتطبيقات. ويتم جمع وتحليل هذه المعلومات
							مع المعلومات الأخرى لتحسين مستوي الخدمة.
						</p>
						<p>
							٦ - لا نستعين في هذا التطبيق بملفات الارتباط (كوكيز) بشكل أساسي،
							ولكن يجوز أن نستخدم تطبيقات وبرامج من مزودين آخرين لتحسين وتطوير
							الخدمة للمستخدم، مثل مزودي التحليلات البيانية ووكالات التسويق من
							التي تستخدم ملفات الارتباط في تطبيقاتهم للتعرف على جهازك.&nbsp;
						</p>
						<p>
							٧ - يتم تخزين جميع المعلومات المذكورة في النقاط السابقة على
							الخوادم الخاصة بنا ويتم تحديث المعلومات في الحالات الأتية:
						</p>
						<ul>
							<li>
								<p>عند التسجيل وانشاء الحساب&nbsp;</p>
							</li>
							<li>
								<p>وقت التقديم على تمويل</p>
							</li>
							<li>
								<p>وبشكل دوري كل اسبوعين</p>
							</li>
						</ul>
						<h4>الغرض والاستخدام للمعلومات التي يتم جمعها</h4>
						<p>
							يتم جمع البيانات والمعلومات المذكورة أعلاه عنك للأسباب التالية:
						</p>
						<p>
							- بغرض التأكد من هوية المستخدم والتحقق منها، واكتشاف أي محاولات
							غير قانونية لانتحال الشخصية أو تقديم معلومات مغلوطة والحد منها. -
							بغرض اتخاذ القرار بالموافقة على طلب التمويل الخاص بك أو رفضه. في
							حالة الموافقة على طلبك، نستخدم هذه المعلومات في تحديد التقييم
							الائتماني الخاص بك وكذلك الحد الأقصى لمبلغ التمويل.&nbsp;
						</p>
						<p>
							- المساعدة في بناء الخوارزميات الخاصة بتحديد درجة التقييم
							الائتماني.
						</p>
						<p>
							&nbsp;- تحليل السلوك الائتماني للعملاء وتقييمه بشكل صحيح. - اتمام
							معاملات التمويل من حيث عمليات السحب والسداد. - الالتزام بالقوانين
							واللوائح الخاصة بإجراءات &quot;اجراءات فحص بيانات العملاء &quot;،
							بالإضافة إلى قوانين غسل الأموال ومكافحة تمويل الإرهاب. - التواصل
							مع العملاء لموافاتهم بعروضنا الترويجية الجديدة التي تتعلق بالخدمات
							التي يهتمون بها.
						</p>
						<h4>الإفصاح عن معلوماتك الشخصية</h4>
						<p>
							نهدف بشكل أساسي إلى الحد من المعلومات التي يتم الإفصاح عنها أو
							مشاركتها، ونحرص على التأكد من استخدامها فقط في الأغراض الصحيحة
							المخصصة إليها. يجوز الإفصاح عن المعلومات الخاصة بالمستخدم
							ومشاركتها مع الجهات التالية:
						</p>
						<p>
							- الشركات الأخرى التي تقع تحت مظلة الشركة الأم الخاصة بنا ويتم
							الإشارة لهم باسم &quot;مجموعة شركاتنا&quot;.&nbsp;
						</p>
						<p>
							- شركات التأمين أو جهات الائتمان الأخرى في حالة ربط حسابك على
							التطبيق بأحد مزودي المحافظ الالكترونية. يجوز مشاركة المعلومات
							والبيانات التي يتم جمعها عنك مع هذه الجهات.&nbsp;
						</p>
						<p>
							- طلب أحد الجهات الحكومية الداخلية أو الدولية مساعدتنا للتحقيق في
							أي نشاط غير قانوني مرتبط بحسابك أو معاملاتك، أو التحري عنه، أو
							منعه - في حالة بيع أو شراء أي أصول أو شركات لها علاقة بمعلومات
							وبيانات العملاء، وكان لزاما علينا الإفصاح عن المعلومات لهذه
							الجهات.&nbsp;
						</p>
						<p>
							- أطراف أخري ملتزمة معنا بعقود لمساعدتنا في العمليات التشغيلية مثل
							تنفيذ المعاملات المالية أو منع عمليات الاحتيال والحماية منها، أو
							التسويق. يرجي ملاحظة أنه في هذه الحالات سنقوم بالإفصاح عن الحد
							الأدنى من المعلومات والبيانات التي تختص فقط بمساعدة هذه الجهة على
							القيام بواجبات أعمالها الموضحة في العقد.&nbsp;
						</p>
						<p>
							- يجوز أن عند الضرورة الإفصاح عن المعلومات الخاصة بك ومشاركتها
							بغرض الالتزام مع متطلبات ولوائح إحدى الجهات الرقابية أو القانونية
							أو لتطبيق شروط الاستخدام أو غيرها من اللوائح أو في حالة التحقيق في
							خرق للنظام أو متابعة وملاحقة المتهربين من سداد أقساطهم وحماية
							الحقوق والملكيات الخاصة بنا، وهذا يشمل مشاركة المعلومات مع الشركات
							والمنظمات بغرض الحماية من عمليات الاحتيال ومخاطر الائتمان.
						</p>
						<h4>حفظ بياناتك</h4>
						<p>
							١- يتم حفظ البيانات والمعلومات الخاصة بك والتي تشمل على سبيل الذكر
							لا الحصر: الاسم ، رقم الهاتف، والبريد الالكتروني، وحسابات التواصل
							الاجتماعي وجهات الاتصال ;علي أجهزة الخوادم داخل نطاق جمهورية مصر
							العربية طبقا للوائح والقوانين الخاصة بذلك، بمعالجة وفحص البيانات
							والمعلومات الخاصة بك للمساعدة في تنفيذ الطلبات والعمليات المطلوبة.
							وبتسجيلك لبياناتك ومعلوماتك لدينا، فأنت تقر بموافقتك على إمكانية
							نقل، حفظ ومعالجة البيانات، كما نتعهد من جانبنا باتخاذ كافة
							الإجراءات والاحتياطات الممكنة للحفاظ وحماية بياناتك ومعلوماتك وفقا
							لسياسة الخصوصية.&nbsp;
						</p>
						<p>
							٢- على المستخدم أن يحافظ على سرية الرقم السري الخاص بفتح حسابك،
							وعدم مشاركته مع أي شخص لضمان أمان وخصوصية معلوماتك ومنع أي دخول
							غير مصرح به لحسابك. على الرغم من أننا سنبذل كل جهدنا لحماية
							بياناتك الشخصية، بمجرد استلامنا للمعلومات، سنقوم باتخاذ كافة
							الإجراءات الممكنة لحمايتها ومنع الوصول غير المصرح اليها.&nbsp;
						</p>
						<h4>الحقوق والواجبات الخاصة بالعملاء</h4>
						<p>
							- في حالة حدوث أي تغييرات في المعلومات والبيانات التي زودتنا بها،
							يتوجب عليك إخطارنا كتابيا فور حدوث ذلك خلال فترة لا تتجاوز (٣٠)
							ثلاثون يوما، كما يجب عليك التواصل والرد على أي طلبات نقوم بطلبها
							بخصوص ذلك. في حالة عدم تمكنك من الرد، من الممكن أن يؤدي ذلك الي
							الإيقاف المؤقت أو الإغلاق الكامل لحسابك وفقا للوائح
							والقوانين.&nbsp;
						</p>
						<p>
							- في حالة قيامك بإلغاء أو إيقاف أي تصريح للدخول على بياناتك، لن
							نتمكن من مواصلة تقديم خدماتنا اليك، ولدينا الحق في إيقاف أو إغلاق
							حسابك وفقا للوائح والقوانين.&nbsp;
						</p>
						<p>
							- في حالة وجود شبهات مالية أو شكوك أو مخاطر بخصوص أي معاملات تخص
							حسابك، سيتم اتخاذ الإجراءات المطلوبة للالتزام باللوائح والقوانين
							التي قد تتطلب إيقاف أو إغلاق حسابك وفقا للوائح والقوانين.&nbsp;
						</p>
						<p>
							- سنقوم بإخطارك في حالة رغبتنا في استخدام أي معلومات خاصة بك في
							عمليات التسويق الخاصة بنا، ولديك الحق في رفض استخدامنا لمعلوماتك
							وابلاغنا بذلك خلال (٧) سبعة أيام من تاريخ ابلاغك.
						</p>
						<h4>تحديث وتعديل سياسة الخصوصية والأسئلة المتعلقة بها</h4>
						<p>
							عند إجراء أي تعديل أو تحديث على سياسة الخصوصية، سنقوم بتحديث هذه
							الصفحة في جميع الأماكن التي تظهر بها، كما سيتم اخطارك عند فتح
							التطبيق بوجود تعديل في سياسة الخصوصية، وسيطلب منك قراءتها
							والموافقة عليها قبل متابعة استخدامك للتطبيق وخدماتنا.
						</p>
						<p>
							في حالة وجود أي أسئلة أو استفسارات أو تعليقات على سياسة الخصوصية،
							يمكنكم مراسلتنا على البريد الالكتروني: contact@kashat.com.eg
						</p>
					</div>
				</div>
			</Layouts>
		</>
	);
};
export default PrivacyPolicyEnglishPage;
